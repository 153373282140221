import React from "react";

import _ from "lodash";

import { CategoryModel } from "model/CategoryModel";

type PropsType = {
    single: boolean;
    onChange: (category: CategoryModel, checked: boolean) => void;
    categories: Array<CategoryModel>;
    selectedCategories?: CategoryModel[];
    disabled?: boolean;
};

export function categoryInArray(
    categories: CategoryModel[] | undefined,
    categoryId: string
): boolean {
    return _.isArray(categories)
        ? categories.find((selectedCategory) => selectedCategory.id === categoryId) !== undefined
        : false;
}

function CategoryTreeSelect({
    single,
    onChange,
    categories,
    selectedCategories,
    disabled,
}: PropsType) {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const [categoriesClosed, setCategoriesClosed] = React.useState<Array<string>>([]);

    return (
        <div className={"category-tree-select"}>
            <button onClick={() => setIsOpen(!isOpen)} className={"main-box"} disabled={disabled}>
                {selectedCategories && selectedCategories.length > 0 ? (
                    <span className={"value"}>
                        {selectedCategories
                            .map((selectedCategory) => selectedCategory.name)
                            .join(", ")}
                    </span>
                ) : (
                    <span className={"placeholder"}>{"Seleziona una categoria"}</span>
                )}
            </button>
            <div
                style={{
                    display: isOpen ? "block" : "none",
                }}
                className={"fixed-background"}
                onClick={() => setIsOpen(false)}
            ></div>
            <div
                style={{
                    display: isOpen ? "flex" : "none",
                }}
                className={"tree-container"}
            >
                <ShowCategories
                    single={single}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    onChange={onChange}
                    categoriesClosed={categoriesClosed}
                    setCategoriesClosed={setCategoriesClosed}
                    setIsOpen={setIsOpen}
                />

                <div>
                    <button onClick={() => setIsOpen(false)}>Chiudi</button>
                </div>
            </div>
        </div>
    );
}

function ShowCategories({
    single,
    categories,
    selectedCategories,
    onChange,
    categoriesClosed,
    setCategoriesClosed,
    setIsOpen,
}: PropsType & {
    categoriesClosed: string[];
    setCategoriesClosed: React.Dispatch<React.SetStateAction<string[]>>;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const openCategory = React.useCallback(
        (category: CategoryModel) => {
            setCategoriesClosed((prevCategories) => {
                const newCategories = _.cloneDeep(prevCategories);

                newCategories.splice(newCategories.indexOf(category.id), 1);

                return newCategories;
            });
        },
        [setCategoriesClosed]
    );

    const closeCategory = React.useCallback(
        (category: CategoryModel) => {
            setCategoriesClosed((prevCategories) => {
                const newCategories = _.cloneDeep(prevCategories);

                newCategories.push(category.id);

                return newCategories;
            });
        },
        [setCategoriesClosed]
    );

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {categories.map((category) => {
                const isCategoryOpen = categoriesClosed.indexOf(category.id) === -1;

                return (
                    <div>
                        <div className={"category-tree-row-item"}>
                            <div className={"more-less-container"}>
                                {category.children.length > 0 && (
                                    <>
                                        {isCategoryOpen ? (
                                            <button
                                                className={"more-less-category"}
                                                onClick={() => closeCategory(category)}
                                            >
                                                -
                                            </button>
                                        ) : (
                                            <button
                                                className={"more-less-category"}
                                                onClick={() => openCategory(category)}
                                            >
                                                +
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                            <div>
                                <input
                                    type={single ? "radio" : "checkbox"}
                                    checked={categoryInArray(selectedCategories, category.id)}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            onChange(category, true);

                                            if (single) {
                                                setIsOpen(false);
                                            }
                                        } else {
                                            if (!single) {
                                                onChange(category, false);
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div>{category.name}</div>
                        </div>

                        {isCategoryOpen && (
                            <div className={"categories-children-container"}>
                                <ShowCategories
                                    single={single}
                                    categories={category.children}
                                    selectedCategories={selectedCategories}
                                    onChange={onChange}
                                    categoriesClosed={categoriesClosed}
                                    setCategoriesClosed={setCategoriesClosed}
                                    setIsOpen={setIsOpen}
                                />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

export default CategoryTreeSelect;
