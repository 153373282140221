import React from "react";

import moment from "moment";

import { categoryInArray } from "component/CategoryTreeSelect";

import { CategoryModel } from "model/CategoryModel";
import { TransactionModel } from "model/TransactionModel";

import Api from "service/Api";

import useStateStorage from "hooks/useStateStorage";

type HookParams = {
    walletId: string;
    storePrefix?: string;
    initialFromValue?: string;
    initialToValue?: string;
};

export type TransactionsWithFiltersHook = {
    loading: boolean;
    filteredTransactionsGive: TransactionModel[];
    filteredTransactionsHave: TransactionModel[];
    filteredTransactions: TransactionModel[];
    categoriesGive: CategoryModel[];
    categoriesHave: CategoryModel[];
    selectedGiveCategories: CategoryModel[];
    selectedHaveCategories: CategoryModel[];
    setSelectedGiveCategories: React.Dispatch<React.SetStateAction<CategoryModel[]>>;
    setSelectedHaveCategories: React.Dispatch<React.SetStateAction<CategoryModel[]>>;
    filtraPerCategorieGive: boolean;
    filtraPerCategorieHave: boolean;
    setFiltraPerCategorieGive: React.Dispatch<React.SetStateAction<boolean>>;
    setFiltraPerCategorieHave: React.Dispatch<React.SetStateAction<boolean>>;
    fromDate: string;
    toDate: string;
    onFromDateChange: (value: string) => void;
    onToDateChange: (value: string) => void;
};

function useTransactionsWithFilters({
    walletId,
    storePrefix,
    initialFromValue,
    initialToValue,
}: HookParams): TransactionsWithFiltersHook {
    const [fromDate, onFromDateChange] = useStateStorage<string>(
        storePrefix ? storePrefix + "-from-Date-" + walletId : null,
        () => {
            if (initialFromValue) {
                return initialFromValue;
            } else {
                return moment().format("YYYY-01-01");
            }
        }
    );

    const [toDate, onToDateChange] = useStateStorage<string>(
        storePrefix ? storePrefix + "-to-Date-" + walletId : null,
        () => {
            if (initialToValue) {
                return initialToValue;
            } else {
                return moment().format("YYYY-12-31");
            }
        }
    );

    const [selectedGiveCategories, setSelectedGiveCategories] = useStateStorage<CategoryModel[]>(
        storePrefix ? storePrefix + "-selected-Give-Categories-" + walletId : null,
        []
    );
    const [selectedHaveCategories, setSelectedHaveCategories] = useStateStorage<CategoryModel[]>(
        storePrefix ? storePrefix + "-selected-Have-Categories-" + walletId : null,
        []
    );

    const [filtraPerCategorieGive, setFiltraPerCategorieGive] = useStateStorage<boolean>(
        storePrefix ? storePrefix + "-filtra-Per-Categorie-Give-" + walletId : null,
        false
    );
    const [filtraPerCategorieHave, setFiltraPerCategorieHave] = useStateStorage<boolean>(
        storePrefix ? storePrefix + "-filtra-Per-Categorie-Have-" + walletId : null,
        false
    );

    const [loading, setLoading] = React.useState(true);

    const [categories, setCategories] = React.useState<{
        categoriesGive: CategoryModel[];
        categoriesHave: CategoryModel[];
    }>({
        categoriesGive: [],
        categoriesHave: [],
    });

    const [transactions, setTransactions] = React.useState<{
        filteredTransactions: TransactionModel[];
        filteredTransactionsGive: TransactionModel[];
        filteredTransactionsHave: TransactionModel[];
    }>({
        filteredTransactions: [],
        filteredTransactionsGive: [],
        filteredTransactionsHave: [],
    });

    React.useEffect(() => {
        Promise.all([Api.categoriesGive(), Api.categoriesHave()]).then((values) => {
            setCategories({
                categoriesGive: values[0] as CategoryModel[],
                categoriesHave: values[1] as CategoryModel[],
            });
        });
    }, []);

    React.useEffect(() => {
        setLoading(true);

        Promise.all([
            Api.transactionsGive(walletId, fromDate, toDate),
            Api.transactionsHave(walletId, fromDate, toDate),
        ]).then((values) => {
            const tmpTransactions: TransactionModel[] = [];
            const tmpTransactionsGive: TransactionModel[] = [];
            const tmpTransactionsHave: TransactionModel[] = [];

            filtraTransazioni(
                values[0] as TransactionModel[],
                filtraPerCategorieGive,
                selectedGiveCategories
            ).forEach((transaction: TransactionModel) => {
                tmpTransactionsGive.push(transaction);

                tmpTransactions.push(transaction);
            });

            filtraTransazioni(
                values[1] as TransactionModel[],
                filtraPerCategorieHave,
                selectedHaveCategories
            ).forEach((transaction: TransactionModel) => {
                tmpTransactionsHave.push(transaction);

                tmpTransactions.push(transaction);
            });

            setTransactions({
                filteredTransactions: tmpTransactions,
                filteredTransactionsGive: tmpTransactionsGive,
                filteredTransactionsHave: tmpTransactionsHave,
            });

            setLoading(false);
        });
    }, [
        walletId,
        fromDate,
        toDate,
        selectedGiveCategories,
        selectedHaveCategories,
        filtraPerCategorieGive,
        filtraPerCategorieHave,
    ]);

    const { filteredTransactions, filteredTransactionsGive, filteredTransactionsHave } =
        transactions;

    return React.useMemo(
        (): TransactionsWithFiltersHook => ({
            loading,
            filteredTransactions,
            filteredTransactionsGive,
            filteredTransactionsHave,
            categoriesGive: categories.categoriesGive,
            categoriesHave: categories.categoriesHave,
            selectedGiveCategories,
            selectedHaveCategories,
            setSelectedGiveCategories,
            setSelectedHaveCategories,
            filtraPerCategorieGive,
            filtraPerCategorieHave,
            setFiltraPerCategorieGive,
            setFiltraPerCategorieHave,
            fromDate,
            toDate,
            onFromDateChange,
            onToDateChange,
        }),
        [
            loading,
            filteredTransactions,
            filteredTransactionsGive,
            filteredTransactionsHave,
            categories,
            selectedGiveCategories,
            selectedHaveCategories,
            setSelectedGiveCategories,
            setSelectedHaveCategories,
            filtraPerCategorieGive,
            filtraPerCategorieHave,
            setFiltraPerCategorieGive,
            setFiltraPerCategorieHave,
            fromDate,
            toDate,
            onFromDateChange,
            onToDateChange,
        ]
    );
}

function filtraTransazioni(
    transactions: TransactionModel[],
    filtraPerCategoria: boolean,
    categorieDaVisualizzare: CategoryModel[]
): TransactionModel[] {
    return transactions.filter((transaction) => {
        // Se non devo filtrare per categoria allora restituisco tutte le transazioni
        if (filtraPerCategoria === false) {
            return true;
        } else {
            const categoryPresente = categoryInArray(
                categorieDaVisualizzare,
                transaction.categoryId
            );

            return categoryPresente;
        }
    });
}

export default useTransactionsWithFilters;
