import React from "react";

import _ from "lodash";

import moment from "moment";

type PropsType = {
    label: string;
    value: string;
    onChange: (value: string) => void;
};

function DatePicker({ label, value, onChange }: PropsType) {
    const [calendarDate, setCalendarDate] = React.useState(() => {
        if (value) {
            return moment(value).date(1);
        } else {
            return moment().date(1);
        }
    });

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const daysArray = React.useMemo(() => {
        const duplicated = _.cloneDeep(calendarDate);

        const daysArray = [];

        for (let i = 1; i < parseInt(duplicated.format("E")); i++) {
            daysArray.push(null);
        }

        for (let i = 1; i <= parseInt(duplicated.endOf("month").format("D")); i++) {
            daysArray.push(i);
        }

        const actualLength = daysArray.length;

        for (let i = 1; i < actualLength % 7; i++) {
            daysArray.push(null);
        }

        return daysArray;
    }, [calendarDate]);

    const downYear = React.useCallback(() => {
        setCalendarDate((prevDate) => {
            return _.cloneDeep(prevDate).subtract(1, "years");
        });
    }, []);

    const upYear = React.useCallback(() => {
        setCalendarDate((prevDate) => {
            return _.cloneDeep(prevDate).add(1, "years");
        });
    }, []);

    const downMonth = React.useCallback(() => {
        setCalendarDate((prevDate) => {
            return _.cloneDeep(prevDate).subtract(1, "months");
        });
    }, []);

    const upMonth = React.useCallback(() => {
        setCalendarDate((prevDate) => {
            return _.cloneDeep(prevDate).add(1, "months");
        });
    }, []);

    const getDate = React.useCallback(
        (day: number | null): string | null => {
            if (day) {
                return calendarDate.format("YYYY-MM-") + ("" + day).padStart(2, "0");
            }

            return null;
        },
        [calendarDate]
    );

    const onDateClick = React.useCallback(
        (day: number | null) => {
            const date = getDate(day);

            if (date) {
                onChange(date);

                setIsOpen(false);
            }
        },
        [onChange, getDate]
    );

    return (
        <div className={"date-picker"}>
            <label>{label}</label>

            <button className={"date-value"} onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}>
                {value}
            </button>

            <div
                style={{
                    display: isOpen ? "block" : "none",
                }}
                className={"fixed-background"}
                onClick={() => setIsOpen(false)}
            ></div>

            <div className={"calendar-container"} style={{ display: isOpen ? "flex" : "none" }}>
                <div className={"year-selector"}>
                    <button onClick={downYear}>-</button>
                    <span>{calendarDate.format("YYYY")}</span>
                    <button onClick={upYear}>+</button>
                </div>
                <div className={"month-selector"}>
                    <button onClick={downMonth}>-</button>
                    <span>{calendarDate.format("MMMM")}</span>
                    <button onClick={upMonth}>+</button>
                </div>
                <div className={"day-selector"}>
                    <table>
                        <thead>
                            <tr>
                                <th>{"lu"}</th>
                                <th>{"ma"}</th>
                                <th>{"me"}</th>
                                <th>{"gi"}</th>
                                <th>{"ve"}</th>
                                <th>{"sa"}</th>
                                <th>{"do"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.chunk(daysArray, 7).map((days, daysIndex) => {
                                return (
                                    <tr key={`days-${daysIndex}`}>
                                        {days.map((day, dayIndex) => {
                                            return (
                                                <td key={`day-${dayIndex}`}>
                                                    {day && (
                                                        <button
                                                            onClick={() => onDateClick(day)}
                                                            className={
                                                                getDate(day) === value
                                                                    ? "selected"
                                                                    : ""
                                                            }
                                                        >
                                                            {day.toString().padStart(2, " ")}
                                                        </button>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className={"cancel-button-container"}>
                    <button onClick={() => setIsOpen(false)}>{"Annulla"}</button>
                </div>
            </div>
        </div>
    );
}

export default DatePicker;
