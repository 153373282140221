import React from "react";

import storage from "utils/storage";

function useStateStorage<T>(storeKey: string | null, initialState: T | (() => T)) {
    const stateHook = React.useState<T>(() => {
        if (storeKey !== null && storage.exists(storeKey)) {
            return storage.get(storeKey);
        } else if (typeof initialState === "function") {
            return (initialState as () => T)();
        } else {
            return initialState;
        }
    });

    const [state] = stateHook;

    React.useEffect(() => {
        if (storeKey !== null) {
            storage.set(storeKey, state);
        }
    }, [storeKey, state]);

    return stateHook;
}

export default useStateStorage;
