import React from "react";

import _ from "lodash";

import CategoryTreeSelect from "component/CategoryTreeSelect";

import { CategoryModel } from "model/CategoryModel";

type PropsType = {
    label: string;
    categories: CategoryModel[];
    selectedCategories: CategoryModel[];
    setSelectedCategories: React.Dispatch<React.SetStateAction<CategoryModel[]>>;
    filtraPerCategorie: boolean;
    setFiltraPerCategorie: React.Dispatch<React.SetStateAction<boolean>>;
};

function CategoriesFilter({
    label,
    categories,
    selectedCategories,
    setSelectedCategories,
    filtraPerCategorie,
    setFiltraPerCategorie,
}: PropsType) {
    return (
        <div className={"categories-filter-container"}>
            <label>{label}</label>

            <div className={"input-container"}>
                <div>
                    <input
                        type={"checkbox"}
                        checked={filtraPerCategorie}
                        onChange={(event) => setFiltraPerCategorie(event.target.checked)}
                    />
                </div>

                <CategoryTreeSelect
                    single={false}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    onChange={(category, checked) => {
                        setSelectedCategories((prevCategories) => {
                            let newCategories;

                            if (checked) {
                                newCategories = _.cloneDeep(prevCategories);

                                newCategories.push(category);
                            } else {
                                newCategories = prevCategories.filter(
                                    (prevCategory) => prevCategory.id !== category.id
                                );
                            }

                            return newCategories;
                        });
                    }}
                />
            </div>
        </div>
    );
}

export default CategoriesFilter;
