import "chartjs-adapter-moment";

import React from "react";

import { Link, useParams } from "react-router-dom";

import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip,
} from "chart.js";

import { Chart } from "react-chartjs-2";

import _ from "lodash";

import moment from "moment";

import TransactionsFilters from "component/TransactionsFilters";

import { TransactionModel } from "model/TransactionModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

import useTransactionsWithFilters from "hooks/useTransactionsWithFilters";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

const options = {
    responsive: true,
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        y: {
            type: "linear" as any,
            display: true,
            position: "left" as any,
        },
        y1: {
            type: "linear" as any,
            display: true,
            position: "right" as any,
            grid: {
                drawOnChartArea: false,
            },
        },
    },
};

function BarGraph() {
    let { walletId } = useParams<any>();

    const [walletData, setWalletData] = React.useState<WalletModel | undefined>(undefined);

    React.useEffect(() => {
        Api.wallet(walletId).then((response) => {
            setWalletData(response as WalletModel);
        });
    }, [walletId]);

    const transactionsFiltersHook = useTransactionsWithFilters({
        walletId,
        storePrefix: "bar-graph",
    });

    const { filteredTransactionsGive, filteredTransactionsHave, fromDate, toDate } =
        transactionsFiltersHook;

    const [groupBy, setGroupBy] = React.useState(
        localStorage.getItem("groupByBarGraph-" + walletId) ?? "YYYY-MM"
    );

    const [data, setData] = React.useState<any>({
        labels: [],
        datasets: [
            {
                type: "bar" as const,
                label: "Uscite",
                data: [],
                backgroundColor: "rgba(250, 22, 22, 0.5)",
                borderColor: "rgba(250, 22, 22, 1)",
                yAxisID: "y",
            },
            {
                type: "bar" as const,
                label: "Entrate",
                data: [],
                backgroundColor: "rgba(3, 148, 39, 0.5)",
                borderColor: "rgba(3, 148, 39, 1)",
                yAxisID: "y",
            },
            {
                type: "line" as const,
                label: "Bilancio mensile",
                data: [],
                backgroundColor: "rgb(8, 162, 196)",
                borderColor: "rgb(8, 162, 196)",
                fill: false,
                yAxisID: "y",
            },
            {
                type: "line" as const,
                label: "Bilancio",
                data: [],
                backgroundColor: "rgb(252, 186, 3)",
                borderColor: "rgb(252, 186, 3)",
                fill: false,
                yAxisID: "y1",
            },
        ],
    });

    const onGroupByChange = (value: string) => {
        localStorage.setItem("groupByBarGraph-" + walletId, value);

        setGroupBy(value);
    };

    React.useEffect(() => {
        if (walletData) {
            const oToDate = moment(toDate);

            const oLabels: {
                [x: string]: { give: number; have: number; balance: number };
            } = {};

            for (
                const oFromDate = moment(fromDate);
                oFromDate <= oToDate;
                oFromDate.add(1, "day")
            ) {
                const formattedDate = oFromDate.format(groupBy);

                if (!(formattedDate in oLabels)) {
                    oLabels[oFromDate.format(groupBy)] = { give: 0, have: 0, balance: 0 };
                }
            }

            filteredTransactionsGive.forEach((transaction: TransactionModel) => {
                oLabels[moment(transaction.date).format(groupBy)].give += transaction.amount;
                oLabels[moment(transaction.date).format(groupBy)].balance -= transaction.amount;
            });

            filteredTransactionsHave.forEach((transaction: TransactionModel) => {
                oLabels[moment(transaction.date).format(groupBy)].have += transaction.amount;
                oLabels[moment(transaction.date).format(groupBy)].balance += transaction.amount;
            });

            setData((prevData: any) => {
                const newData = _.cloneDeep(prevData);

                newData.labels = [];

                newData.datasets[0].data = [];
                newData.datasets[1].data = [];
                newData.datasets[2].data = [];
                newData.datasets[3].data = [];

                _.forEach(oLabels, (item, key) => {
                    newData.labels.push(key);

                    newData.datasets[0].data.push(item.give);
                    newData.datasets[1].data.push(item.have);
                    newData.datasets[2].data.push(item.balance);
                });

                _.reduce(
                    oLabels,
                    (lastBalance, item) => {
                        const newBalance = lastBalance + item.balance;

                        newData.datasets[3].data.push(newBalance);

                        return newBalance;
                    },
                    0
                );

                return newData;
            });
        }
    }, [filteredTransactionsGive, filteredTransactionsHave, fromDate, groupBy, toDate, walletData]);

    return (
        <>
            {walletData && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginBottom: "1rem" }}>
                        <Link to={`/wallets/${walletData.id}/transactions`}>Indietro</Link>
                    </div>

                    <div style={{ marginBottom: "1rem" }}>{walletData.name}</div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "2rem",
                        }}
                    >
                        <TransactionsFilters transactionsFiltersHook={transactionsFiltersHook} />

                        <div style={{ maxWidth: "170px", marginTop: "1rem" }}>
                            <div style={{ marginRight: "5px" }}>
                                <span>{"Group by"}</span>
                            </div>
                            <div style={{ marginRight: "15px" }}>
                                <select
                                    onChange={(event) => {
                                        onGroupByChange(event.target.value);
                                    }}
                                    value={groupBy}
                                    style={{ width: "100%" }}
                                >
                                    <option value="YYYY-MM">Month</option>
                                    <option value="YYYY">Year</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <Chart type="bar" data={data} options={options} />
                </div>
            )}
        </>
    );
}

export default BarGraph;
