import React from "react";

import CategoriesFilter from "component/CategoriesFilter";
import DatePicker from "component/DatePicker";

import { TransactionsWithFiltersHook } from "hooks/useTransactionsWithFilters";

function TransactionsFilters({
    transactionsFiltersHook,
}: {
    transactionsFiltersHook: TransactionsWithFiltersHook;
}) {
    const {
        categoriesGive,
        categoriesHave,
        selectedGiveCategories,
        selectedHaveCategories,
        setSelectedGiveCategories,
        setSelectedHaveCategories,
        filtraPerCategorieGive,
        filtraPerCategorieHave,
        setFiltraPerCategorieGive,
        setFiltraPerCategorieHave,
        fromDate,
        toDate,
        onFromDateChange,
        onToDateChange,
    } = transactionsFiltersHook;

    return (
        <div style={{ display: "flex", flexDirection: "row", columnGap: "1.5rem" }}>
            <DatePicker label={"Dal"} value={fromDate} onChange={onFromDateChange} />

            <DatePicker label={"Al"} value={toDate} onChange={onToDateChange} />

            <CategoriesFilter
                label={"Filtra per categorie dare"}
                categories={categoriesGive}
                selectedCategories={selectedGiveCategories}
                setSelectedCategories={setSelectedGiveCategories}
                filtraPerCategorie={filtraPerCategorieGive}
                setFiltraPerCategorie={setFiltraPerCategorieGive}
            />

            <CategoriesFilter
                label={"Filtra per categorie avere"}
                categories={categoriesHave}
                selectedCategories={selectedHaveCategories}
                setSelectedCategories={setSelectedHaveCategories}
                filtraPerCategorie={filtraPerCategorieHave}
                setFiltraPerCategorie={setFiltraPerCategorieHave}
            />
        </div>
    );
}

export default TransactionsFilters;
