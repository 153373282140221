import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import _ from "lodash";

import Papa from "papaparse";

import CategoryTreeSelect from "component/CategoryTreeSelect";

import { CategoryModel } from "model/CategoryModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

import { setValueByPath } from "utils/form";

type CsvRow = {
    date: string;
    amount: number;
    stringAmount: string;
    description: string;
    confirmed: boolean;
    note: string | null;
    category: CategoryModel | null;
    direction: "give" | "have";
};

function searchCategoryByName(categories: CategoryModel[], name: string): CategoryModel | null {
    for (const category of categories) {
        if (category.name === name) {
            return _.cloneDeep(category);
        } else {
            const foundCategory = searchCategoryByName(category.children, name);

            if (foundCategory !== null) {
                return foundCategory;
            }
        }
    }

    return null;
}

function getCategory(
    categoriesGive: CategoryModel[],
    categoriesHave: CategoryModel[],
    description: string
): CategoryModel | null {
    let category = null;

    if (description.indexOf("Addebito Preautorizzato SDD") !== -1) {
        category = searchCategoryByName(categoriesGive, "SDD");
    } else if (description.indexOf("MUTUO IPOTECARIO PAGAMENTO RATA") !== -1) {
        category = searchCategoryByName(categoriesGive, "Prestito");
    } else if (description.indexOf("PENS. N.2401") !== -1) {
        category = searchCategoryByName(categoriesHave, "Pensione");
    } else if (description.indexOf("SKY ID MANDATO") !== -1) {
        category = searchCategoryByName(categoriesGive, "Sky");
    } else if (description.indexOf("EOLO SPA ID MANDATO") !== -1) {
        category = searchCategoryByName(categoriesGive, "Internet");
    } else if (description.indexOf("LARIO RETI HOLDING SPA ID MANDATO") !== -1) {
        category = searchCategoryByName(categoriesGive, "Acqua");
    } else if (description.indexOf("NEXI PAYMENTS SPA ID MANDATO") !== -1) {
        category = searchCategoryByName(categoriesGive, "Carta di credito");
    } else if (description.indexOf("ENEL ENERGIA SPA ID MANDATO") !== -1) {
        category = searchCategoryByName(categoriesGive, "Luce");
    }

    return category;
}

function ImportTransactionsFromCsv() {
    let { walletId } = useParams() as any;

    const [loading, setLoading] = useState(true);

    const [walletData, setWalletData] = useState({} as WalletModel);
    const [categoriesGive, setCategoriesGive] = useState([] as CategoryModel[]);
    const [categoriesHave, setCategoriesHave] = useState([] as CategoryModel[]);

    const [file, setFile] = useState();
    const [rows, setRows] = useState([] as CsvRow[]);

    const handleOnChange = React.useCallback((e) => {
        setFile(e.target.files[0]);
    }, []);

    useEffect(() => {
        setLoading(true);

        Promise.all([Api.wallet(walletId), Api.categoriesGive(), Api.categoriesHave()]).then(
            (values) => {
                console.log(values[1]);

                setWalletData(values[0] as WalletModel);
                setCategoriesGive(values[1] as CategoryModel[]);
                setCategoriesHave(values[2] as CategoryModel[]);

                setLoading(false);
            }
        );
    }, [walletId]);

    const onButtonClick = React.useCallback(() => {
        if (file) {
            const fileReader = new FileReader();

            fileReader.onload = function (event) {
                if (event?.target?.result) {
                    const csvString = event.target.result as string;

                    const config = {
                        delimiter: ";",
                        quoteChar: '"',
                    };

                    const parseResult = Papa.parse(csvString, config);

                    const tmpRows: Array<Array<string>> = _.cloneDeep(parseResult.data) as any;

                    tmpRows.splice(0, 2);

                    setRows(
                        tmpRows
                            .filter(
                                (row) => row.length === 6 && row[2].indexOf("SALDO FINALE") === -1
                            )
                            .map((row) => {
                                const amount = parseFloat(row[4]);

                                return {
                                    amount: amount < 0 ? amount * -1 : amount,
                                    stringAmount: row[4],
                                    description: ("" + row[3]).trim() !== "" ? row[3] : row[2],
                                    date: row[1].split("/").reverse().join("-"),
                                    confirmed: false,
                                    category: getCategory(
                                        categoriesGive,
                                        categoriesHave,
                                        "" + row[3]
                                    ),
                                    note: null,
                                    direction: amount < 0 ? "give" : "have",
                                };
                            })
                    );
                }
            };

            fileReader.readAsText(file);
        }
    }, [file, categoriesGive, categoriesHave]);

    const changeRow = React.useCallback(
        (index, field, value) => {
            let canChange = true;

            if (field === "confirmed" && value === true && rows[index].category === null) {
                canChange = false;
            }

            if (canChange) {
                setRows((prevRows) => {
                    const newRows = _.cloneDeep(prevRows);

                    setValueByPath(newRows, index + "." + field, value);

                    return newRows;
                });
            }
        },
        [rows]
    );

    const confirmButton = React.useCallback(() => {
        const promises: Array<Promise<unknown>> = [];

        rows.forEach((row) => {
            if (row.confirmed && row.category) {
                const apiFunction =
                    row.direction === "give"
                        ? Api.createTransactionGive
                        : Api.createTransactionHave;

                promises.push(
                    apiFunction({
                        id: null,
                        categoryId: row.category.id,
                        date: row.date,
                        note: row.note ?? "",
                        amount: row.amount,
                        walletId: walletData.id,
                        includeInGrandTotal: true,
                    })
                );
            }
        });

        Promise.all(promises).then(() => {
            setRows((prevRows) => {
                const newRows = _.cloneDeep(prevRows);

                return newRows.filter((row) => !(row.confirmed && row.category));
            });
        });
    }, [walletData, rows]);

    return !loading ? (
        <div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <Link to={"/wallets/" + walletData.id + "/transactions"}>Indietro</Link>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                {walletData.name}
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ marginRight: "20px" }}>
                    <input type={"file"} accept={".csv"} onChange={handleOnChange} />
                </div>
                <div style={{ marginRight: "20px" }}>
                    <button onClick={onButtonClick}>Importa</button>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {rows.map((row, i) => {
                    return (
                        <div
                            key={i}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "20px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                borderBottom: "1px solid black",
                            }}
                        >
                            <div>
                                <input
                                    type={"number"}
                                    value={row.amount}
                                    onChange={(event) => changeRow(i, "amount", event.target.value)}
                                    disabled={row.confirmed}
                                />
                            </div>
                            <div>
                                <input
                                    type={"text"}
                                    value={row.date}
                                    onChange={(event) => changeRow(i, "date", event.target.value)}
                                    disabled={row.confirmed}
                                />
                            </div>
                            <div>
                                <CategoryTreeSelect
                                    single={true}
                                    categories={
                                        row.direction === "give" ? categoriesGive : categoriesHave
                                    }
                                    selectedCategories={row.category ? [row.category] : undefined}
                                    onChange={(category) => changeRow(i, "category", category)}
                                    disabled={row.confirmed}
                                />
                            </div>
                            <div>
                                <input
                                    type={"text"}
                                    value={row.note ?? ""}
                                    onChange={(event) => changeRow(i, "note", event.target.value)}
                                    disabled={row.confirmed}
                                />
                            </div>
                            <div>
                                <input
                                    type={"checkbox"}
                                    checked={row.confirmed}
                                    onChange={(event) =>
                                        changeRow(i, "confirmed", event.target.checked)
                                    }
                                />
                            </div>
                            <div>
                                <p style={{ margin: 0 }}>{row.description}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div>
                <button onClick={confirmButton}>{"Salva transazioni"}</button>
            </div>
        </div>
    ) : (
        <div>Loading</div>
    );
}

export default ImportTransactionsFromCsv;
