import "chartjs-adapter-moment";

import React from "react";

import { Link, useParams } from "react-router-dom";

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from "chart.js";

import { Line } from "react-chartjs-2";

import _ from "lodash";

import moment from "moment";

import TransactionsFilters from "component/TransactionsFilters";

import { TransactionModel } from "model/TransactionModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

import { unsetValueByPath } from "utils/form";
import order from "utils/order";
import storage from "utils/storage";

import useTransactionsWithFilters from "hooks/useTransactionsWithFilters";

export type TransactionsGroup = {
    id: number;
    transactions: TransactionModel[];
    transactionsGive: TransactionModel[];
    transactionsHave: TransactionModel[];
    from: string;
    to: string;
};

export type TransactionsGroupsType = {
    groups: Array<TransactionsGroup>;
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    type: "line",
    responsive: true,
    scales: {
        x: {
            type: "linear" as const,
            min: 0,
            max: 370,
        },
        y: {
            min: -2500,
            max: 10000,
        },
    },
};

type IntervalPropsType = {
    walletId: string;
    group: TransactionsGroup;
    setTransactionsGroups: React.Dispatch<React.SetStateAction<TransactionsGroupsType>>;
};

function Interval({ walletId, group, setTransactionsGroups }: IntervalPropsType) {
    const removeGroup = React.useCallback(
        (group: TransactionsGroup) => {
            setTransactionsGroups((prevValue) => {
                const newValue = _.cloneDeep(prevValue);

                const index = newValue.groups.findIndex((item) => item.id === group.id);

                unsetValueByPath(newValue, `groups.${index}`);

                return newValue;
            });
        },
        [setTransactionsGroups]
    );

    const transactionsFiltersHook = useTransactionsWithFilters({
        walletId,
        initialFromValue: group.from,
        initialToValue: group.to,
    });

    const {
        filteredTransactions,
        filteredTransactionsGive,
        filteredTransactionsHave,
        fromDate,
        toDate,
    } = transactionsFiltersHook;

    const { id: groupId } = group;

    React.useEffect(() => {
        setTransactionsGroups((prevValue) => {
            const newValue = _.cloneDeep(prevValue);

            const index = newValue.groups.findIndex((item) => item.id === groupId);

            newValue.groups[index].from = fromDate;
            newValue.groups[index].to = toDate;
            newValue.groups[index].transactions = [];
            newValue.groups[index].transactionsGive = [];
            newValue.groups[index].transactionsHave = [];

            return newValue;
        });
    }, [setTransactionsGroups, groupId, fromDate, toDate]);

    React.useEffect(() => {
        setTransactionsGroups((prevValue) => {
            const newValue = _.cloneDeep(prevValue);

            const index = newValue.groups.findIndex((item) => item.id === groupId);

            newValue.groups[index].transactions = filteredTransactions;
            newValue.groups[index].transactionsGive = filteredTransactionsGive;
            newValue.groups[index].transactionsHave = filteredTransactionsHave;

            return newValue;
        });
    }, [
        setTransactionsGroups,
        groupId,
        filteredTransactions,
        filteredTransactionsGive,
        filteredTransactionsHave,
    ]);

    return (
        <div style={{ display: "flex", flexDirection: "row", marginBottom: "15px" }}>
            <TransactionsFilters transactionsFiltersHook={transactionsFiltersHook} />

            <div style={{ paddingTop: "17px" }}>
                <button onClick={() => removeGroup(group)}>Elimina gruppo</button>
            </div>
        </div>
    );
}

type DatasetType = { borderColor: string; label: string; data: Array<{ x: number; y: number }> };

type DataType = {
    datasets: Array<DatasetType>;
};

const availableColors = ["#9c27b0", "#d32f2f", "#ed6c02", "#0288d1", "#2e7d32"];

function CompareGraph() {
    let { walletId } = useParams<any>();

    const [walletData, setWalletData] = React.useState<WalletModel | undefined>(undefined);

    const [data, setData] = React.useState<DataType>({
        datasets: [],
    });

    const storeKey = "compare-graph-groups";

    const [transactionsGroups, setTransactionsGroups] = React.useState<TransactionsGroupsType>(
        () => {
            const initialState: TransactionsGroupsType = { groups: [] };

            if (storage.exists(storeKey)) {
                (
                    storage.get(storeKey) as Array<{
                        id: number;
                        from: string;
                        to: string;
                    }>
                ).forEach((group) => {
                    initialState.groups.push({
                        ...group,
                        transactions: [],
                        transactionsGive: [],
                        transactionsHave: [],
                    });
                });
            }

            return initialState;
        }
    );

    React.useEffect(() => {
        console.log(transactionsGroups);

        const valueToSave = transactionsGroups.groups.map((group) => ({
            id: group.id,
            from: group.from,
            to: group.to,
        }));

        console.log(valueToSave);

        storage.set(storeKey, valueToSave);
    }, [transactionsGroups]);

    React.useEffect(() => {
        Api.wallet(walletId).then((response) => {
            setWalletData(response as WalletModel);
        });
    }, [walletId]);

    const addGroup = React.useCallback(() => {
        setTransactionsGroups((prevValue) => {
            const newValue = _.cloneDeep(prevValue);

            newValue.groups.push({
                id: parseInt(moment().format("x")),
                from: moment().format("YYYY-01-01"),
                to: moment().format("YYYY-12-31"),
                transactions: [],
                transactionsGive: [],
                transactionsHave: [],
            });

            return newValue;
        });
    }, []);

    React.useEffect(() => {
        if (walletData) {
            const newData: DataType = {
                datasets: [],
            };

            transactionsGroups.groups.forEach((group, i) => {
                const dataset: DatasetType = {
                    borderColor: availableColors[i],
                    label: `Periodo ${group.from} - ${group.to}`,
                    data: [],
                };

                const dates: { [x: string]: { balance: number | null } } = {};

                for (
                    let date = moment(group.from, "YYYY-MM-DD");
                    date.isSameOrBefore(moment(group.to, "YYYY-MM-DD"));
                    date.add(1, "day")
                ) {
                    dates[date.format("YYYY-MM-DD")] = {
                        balance: null,
                    };
                }

                let lastBalance = 0;

                const transactions = _.cloneDeep(group.transactions);

                order(transactions, "date", "ASC");

                transactions.forEach((transaction) => {
                    if (parseInt("" + transaction.amount) !== 10000) {
                        lastBalance += (transaction.type === "give" ? -1 : 1) * transaction.amount;

                        const dateIndex = moment(transaction.date).format("YYYY-MM-DD");

                        if (dateIndex in dates) {
                            dates[dateIndex].balance = lastBalance;
                        }
                    }
                });

                let x = 0;

                _.forEach(dates, (obj) => {
                    if (obj.balance !== null) {
                        dataset.data.push({
                            x: x,
                            y: obj.balance,
                        });
                    }

                    x++;
                });

                newData.datasets.push(dataset);
            });

            setData(newData);
        }
    }, [transactionsGroups.groups, walletData]);

    return (
        <>
            {walletData && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginBottom: "1rem" }}>
                        <Link to={`/wallets/${walletData.id}/transactions`}>Indietro</Link>
                    </div>

                    <div style={{ marginBottom: "1rem" }}>{walletData.name}</div>

                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
                        {transactionsGroups.groups.map((group) => (
                            <Interval
                                key={group.id}
                                walletId={walletId}
                                group={group}
                                setTransactionsGroups={setTransactionsGroups}
                            />
                        ))}

                        <div>
                            <button onClick={addGroup}>Aggiungi gruppo</button>
                        </div>
                    </div>

                    <Line options={options} data={data} />
                </div>
            )}
        </>
    );
}

export default CompareGraph;
