const set = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const get = (key: string): any => {
    return JSON.parse(localStorage.getItem(key) as string);
};

const remove = (key: string) => {
    localStorage.removeItem(key);
};

const exists = (key: string) => {
    return localStorage.getItem(key) !== null;
};

export default {
    set,
    get,
    remove,
    exists,
};
