import "chartjs-adapter-moment";

import React from "react";

import { Link, useParams } from "react-router-dom";

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from "chart.js";

import { Line } from "react-chartjs-2";

import _ from "lodash";

import moment from "moment";

import TransactionsFilters from "component/TransactionsFilters";

import { TransactionModel } from "model/TransactionModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

import order from "utils/order";

import useTransactionsWithFilters from "hooks/useTransactionsWithFilters";

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    scales: {
        xAxis: {
            type: "time" as const,
        },
    },
};

function BalanceGraph() {
    let { walletId } = useParams<any>();

    const [walletData, setWalletData] = React.useState<WalletModel | undefined>(undefined);

    React.useEffect(() => {
        Api.wallet(walletId).then((response) => {
            setWalletData(response as WalletModel);
        });
    }, [walletId]);

    const transactionsFiltersHook = useTransactionsWithFilters({
        walletId,
        storePrefix: "balance-graph",
    });

    const { filteredTransactions } = transactionsFiltersHook;

    const [data, setData] = React.useState<any>({
        datasets: [
            {
                label: "Dataset 1",
                data: [],
            },
        ],
    });

    React.useEffect(() => {
        if (walletData) {
            setData((prevData: any) => {
                const newData = _.cloneDeep(prevData);

                newData.datasets[0].data = [];

                const groups: {
                    [x: string]: {
                        balance: number;
                        date: moment.Moment;
                        dateFormatted: string;
                    };
                } = {};

                order(filteredTransactions, "date", "DESC");

                filteredTransactions.forEach((transaction: TransactionModel) => {
                    const groupKey: string = moment(transaction.date).format("YYYY-MM-DD");

                    if (!(groupKey in groups)) {
                        groups[groupKey] = {
                            balance: 0,
                            date: moment(transaction.date),
                            dateFormatted: moment(transaction.date).format("YYYY-MM-DD"),
                        };
                    }

                    if (transaction.type === "give") {
                        groups[groupKey].balance -= transaction.amount;
                    } else {
                        groups[groupKey].balance += transaction.amount;
                    }
                });

                _.reduce(
                    groups,
                    (lastBalance, group) => {
                        newData.datasets[0].data.push({
                            x: group.date,
                            y: lastBalance,
                        });

                        return lastBalance - group.balance;
                    },
                    walletData.balance
                );

                return newData;
            });
        }
    }, [walletData, filteredTransactions]);

    return (
        <>
            {walletData && (
                <div style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}>
                    <div>
                        <Link to={`/wallets/${walletData.id}/transactions`}>Indietro</Link>
                    </div>

                    <div>{walletData.name}</div>

                    <TransactionsFilters transactionsFiltersHook={transactionsFiltersHook} />

                    <Line options={options} data={data} />
                </div>
            )}
        </>
    );
}

export default BalanceGraph;
