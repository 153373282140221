import "chartjs-adapter-moment";

import React from "react";

import { Link, useParams } from "react-router-dom";

import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";

import { Bar } from "react-chartjs-2";

import _ from "lodash";

import moment from "moment";

import TransactionsFilters from "component/TransactionsFilters";

import { TransactionModel } from "model/TransactionModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

import useTransactionsWithFilters from "hooks/useTransactionsWithFilters";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const initialData = (): {
    labels: Array<string>;
    datasets: Array<{
        label: string;
        data: Array<number>;
        backgroundColor: Array<string>;
        borderColor: Array<string>;
    }>;
} => ({
    labels: [],
    datasets: [
        {
            label: "Bilancio",
            data: [],
            backgroundColor: [],
            borderColor: [],
        },
    ],
});

const options = {
    responsive: true,
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
};

function DiffGraph() {
    let { walletId } = useParams<any>();

    const [walletData, setWalletData] = React.useState<WalletModel | undefined>(undefined);

    React.useEffect(() => {
        Api.wallet(walletId).then((response) => {
            setWalletData(response as WalletModel);
        });
    }, [walletId]);

    const transactionsFiltersHook = useTransactionsWithFilters({
        walletId,
        storePrefix: "diff-graph",
    });

    const { filteredTransactionsGive, filteredTransactionsHave, fromDate, toDate } =
        transactionsFiltersHook;

    const [groupBy, setGroupBy] = React.useState(
        localStorage.getItem("groupByDiffGraph-" + walletId) ?? "YYYY-MM"
    );

    const [data, setData] = React.useState<any>(initialData());

    const onGroupByChange = React.useCallback(
        (value: string) => {
            localStorage.setItem("groupByDiffGraph-" + walletId, value);

            setGroupBy(value);
        },
        [walletId]
    );

    React.useEffect(() => {
        if (walletData) {
            const oToDate = moment(toDate);

            const oLabels: { [x: string]: { total: number } } = {};

            for (
                const oFromDate = moment(fromDate);
                oFromDate <= oToDate;
                oFromDate.add(1, "day")
            ) {
                const formattedDate = oFromDate.format(groupBy);

                if (!(formattedDate in oLabels)) {
                    oLabels[oFromDate.format(groupBy)] = { total: 0 };
                }
            }

            filteredTransactionsGive.forEach((transaction: TransactionModel) => {
                oLabels[moment(transaction.date).format(groupBy)].total -= transaction.amount;
            });

            filteredTransactionsHave.forEach((transaction: TransactionModel) => {
                oLabels[moment(transaction.date).format(groupBy)].total += transaction.amount;
            });

            setData(() => {
                const newData = initialData();

                _.forEach(oLabels, (item, key) => {
                    newData.labels.push(key);

                    newData.datasets[0].data.push(item.total);

                    if (item.total >= 0) {
                        newData.datasets[0].backgroundColor.push("rgba(3, 148, 39, 0.5)");
                        newData.datasets[0].borderColor.push("rgba(3, 148, 39, 1)");
                    } else {
                        newData.datasets[0].backgroundColor.push("rgba(250, 22, 22, 0.5)");
                        newData.datasets[0].borderColor.push("rgba(250, 22, 22, 1)");
                    }
                });

                return newData;
            });
        }
    }, [filteredTransactionsGive, filteredTransactionsHave, fromDate, groupBy, toDate, walletData]);

    return (
        <>
            {walletData && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginBottom: "1rem" }}>
                        <Link to={`/wallets/${walletData.id}/transactions`}>Indietro</Link>
                    </div>

                    <div style={{ marginBottom: "1rem" }}>{walletData.name}</div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "2rem",
                        }}
                    >
                        <TransactionsFilters transactionsFiltersHook={transactionsFiltersHook} />

                        <div style={{ maxWidth: "170px", marginTop: "1rem" }}>
                            <div style={{ marginRight: "5px" }}>
                                <span>{"Group by"}</span>
                            </div>
                            <div style={{ marginRight: "15px" }}>
                                <select
                                    onChange={(event) => {
                                        onGroupByChange(event.target.value);
                                    }}
                                    value={groupBy}
                                    style={{ width: "100%" }}
                                >
                                    <option value="YYYY-MM">Month</option>
                                    <option value="YYYY">Year</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <Bar options={options} data={data} />
                </div>
            )}
        </>
    );
}

export default DiffGraph;
