import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import _ from "lodash";

import numeral from "numeral";

import { CSVLink } from "react-csv";

import TransactionsFilters from "component/TransactionsFilters";
import TransactionsVisualizer from "component/TransactionsVisualizer";

import { TransactionModel } from "model/TransactionModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

import order from "utils/order";

import useTransactionsWithFilters from "hooks/useTransactionsWithFilters";

function Transactions() {
    let { walletId } = useParams() as any;

    const [walletData, setWalletData] = useState({} as WalletModel);
    const [groupBy, setGroupBy] = useState(localStorage.getItem("groupBy-" + walletId) ?? "day");
    const [groupByCategory, setGroupByCategory] = useState(
        localStorage.getItem("groupByCategory-" + walletId) ?? "false"
    );
    const [showTransactions, setShowTransactions] = useState(
        localStorage.getItem("showTransactions-" + walletId) ?? "false"
    );
    const [initialBalance, setInitialBalance] = useState(0 as number);

    const onGroupByCategory = (event: any) => {
        const value = event.target.checked ? "true" : "false";

        localStorage.setItem("groupByCategory-" + walletId, value);

        setGroupByCategory(value);
    };

    const onSelectGroupBy = (value: string) => {
        localStorage.setItem("groupBy-" + walletId, value);

        setGroupBy(value);
    };

    const onShowTransactionsClick = (event: any) => {
        const value = event.target.checked ? "true" : "false";

        localStorage.setItem("showTransactions-" + walletId, value);

        setShowTransactions(value);
    };

    const transactionsFiltersHook = useTransactionsWithFilters({
        walletId,
        storePrefix: "transactions-list",
    });

    const { filteredTransactions, fromDate, loading } = transactionsFiltersHook;

    useEffect(() => {
        Promise.all([Api.wallet(walletId), Api.balance(walletId, fromDate)]).then((values) => {
            setWalletData(values[0] as WalletModel);

            setInitialBalance(values[1] as number);
        });
    }, [walletId, fromDate]);

    const csvData = React.useMemo(() => {
        const newTransactions: TransactionModel[] = _.cloneDeep(filteredTransactions);

        order(newTransactions, "date", "ASC");

        return newTransactions;
    }, [filteredTransactions]);

    return (
        <div>
            <Link to={"/wallets"}>Indietro</Link>
            <br />
            <br />
            {walletData.name} - {numeral(walletData.balance).format("0.00")} -{" "}
            {numeral(initialBalance).format("0.00") + " @ " + fromDate}
            <br />
            <br />
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/balance-graph"}>
                        Grafico del bilancio
                    </Link>
                </div>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/bar-graph"}>Grafico a barre</Link>
                </div>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/diff-graph"}>
                        Grafico bilancio a barre
                    </Link>
                </div>
                <div>
                    <Link to={"/wallets/" + walletData.id + "/compare-graph"}>
                        Grafico comparazione
                    </Link>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/transactions-give/new"}>
                        Nuova uscita
                    </Link>
                </div>
                <div>
                    <Link to={"/wallets/" + walletData.id + "/transactions-have/new"}>
                        Nuova entrata
                    </Link>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/transactions-give/new-multiple"}>
                        Nuova uscita multipla
                    </Link>
                </div>
                <div>
                    <Link to={"/wallets/" + walletData.id + "/transactions-have/new-multiple"}>
                        Nuova entrata multipla
                    </Link>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/import-transactions-from-csv"}>
                        Importa transazioni da CSV
                    </Link>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={(event) => onGroupByCategory(event)}
                        checked={groupByCategory === "true"}
                    />{" "}
                    Group by category
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ padding: "7px", paddingLeft: 0, marginRight: "5px" }}>Group by</div>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => onSelectGroupBy("none")}
                        checked={groupBy === "none"}
                    />{" "}
                    None
                </div>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => onSelectGroupBy("day")}
                        checked={groupBy === "day"}
                    />{" "}
                    Day
                </div>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => onSelectGroupBy("week")}
                        checked={groupBy === "week"}
                    />{" "}
                    Week
                </div>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => onSelectGroupBy("month")}
                        checked={groupBy === "month"}
                    />{" "}
                    Month
                </div>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => onSelectGroupBy("year")}
                        checked={groupBy === "year"}
                    />{" "}
                    Year
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={(event) => onShowTransactionsClick(event)}
                        checked={showTransactions === "true"}
                    />{" "}
                    Show transactions
                </div>
            </div>
            <TransactionsFilters transactionsFiltersHook={transactionsFiltersHook} />
            <br />
            <br />
            <CSVLink data={csvData}>Download CSV</CSVLink>
            <br />
            <br />
            {!loading && (
                <TransactionsVisualizer
                    transactions={filteredTransactions}
                    groupBy={groupBy}
                    groupByCategory={groupByCategory === "true"}
                    showTransactions={showTransactions === "true"}
                />
            )}
        </div>
    );
}

export default Transactions;
